import React from "react"
import Disclaimers from "@tightrope/disclaimers"
import "./TemplateContainer.css"
import data from "../data/data.json"

export default function TemplateContainer() {
  return (
    <div className="template-wrapper">
      <div className="template-container">
      <img src="/img/logo-light.png" alt="Logo" className="logo" />
        
        <div className="header">
          <div className="header-top">
            <img src="/img/ml-icon.png" alt="Manuals Library Icon" />
            <h1>{data.title}</h1>
          </div>
          <p>{data.description}</p>
        </div>

        <div style={{ textAlign: 'center' }}>
          <ul className="bullet-points">
            {data.bulletPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>

        <div className="steps">
          {data.steps.map((step, index) => (
            <div key={index} className="step">
              <div className="step-number">{step.number}</div>
              <p className="step-description">
                <span className="step-label">{step.step}</span>
                <span className="step-text">{step.description}</span>
              </p>
            </div>
          ))}
        </div>

          <button onClick = {() => window.triggerInstall()} className="template-button">{data.buttonText}</button>
          <Disclaimers language='en-chrome'></Disclaimers>
          
      </div>
    </div>
  )
} 